import axios from "axios";
import {
  CREATE_ROLE,
  CREATE_ROLE_ACTION,
  DELETE_ROLE,
  DELETE_ROLE_ACTION,
  GET_ROLE_ACTIONS,
  GET_ROLES,
  UPDATE_ROLE,
  UPDATE_ROLE_ACTION
} from "./actions.type";
import {
  REMOVE_ROLE,
  REMOVE_ROLE_ACTION,
  SET_ROLE,
  SET_ROLE_ACTION,
  SET_ROLE_ACTIONS,
  SET_ROLES
} from "./mutations.type";
import { baseUrl } from "../../config/config";
import { addSuccessToast, withErrorHandling } from "./utils";

export const RoleTypes = {
  USER_ROLE: "USER_ROLE",
  CITY: "CITY",
  FACILITY: "FACILITY"
};
Object.freeze(RoleTypes);

const state = {
  roles: new Map(),
  actions: []
};

const getters = {
  roles(state) {
    return state.roles;
  },
  actions(state) {
    return state.actions;
  }
};

const actions = {
  [GET_ROLES](context, roleType) {
    if (context.state.roles.has(roleType)) {
      return Promise.resolve();
    }
    return withErrorHandling(
      context,
      axios({
        url: baseUrl + "roles",
        withCredentials: true,
        method: "GET",
        params: {
          nextToken: "",
          rows: 1000,
          roleType: roleType
        }
      }),
      res => {
        context.commit(SET_ROLES, {
          type: roleType,
          roles: res.data.rows
        });
      }
    );
  },
  [CREATE_ROLE](context, data) {
    return withErrorHandling(
      context,
      axios({
        url: baseUrl + "roles",
        withCredentials: true,
        method: "POST",
        data: data
      }),
      res => {
        context.commit(SET_ROLE, res.data.obj);
        addSuccessToast(context, "角色已建立");
      }
    );
  },
  [UPDATE_ROLE](context, data) {
    return withErrorHandling(
      context,
      axios({
        url: baseUrl + "roles",
        withCredentials: true,
        method: "PUT",
        data: data
      }),
      res => {
        context.commit(SET_ROLE, res.data.obj);
        addSuccessToast(context, "角色编辑成功！");
      }
    );
  },
  [DELETE_ROLE](context, roleId) {
    return withErrorHandling(
      context,
      axios({
        url: baseUrl + "roles/" + roleId,
        withCredentials: true,
        method: "DELETE"
      }),
      () => {
        context.commit(REMOVE_ROLE, roleId);
        addSuccessToast(context, "角色删除成功");
      }
    );
  },
  [GET_ROLE_ACTIONS](context) {
    return withErrorHandling(
      context,
      axios({
        url: baseUrl + "roles/actions",
        withCredentials: true,
        method: "GET"
      }),
      res => {
        context.commit(SET_ROLE_ACTIONS, res.data.rows);
      }
    );
  },
  [CREATE_ROLE_ACTION](context, data) {
    return withErrorHandling(
      context,
      axios({
        url: baseUrl + "roles/actions",
        withCredentials: true,
        method: "POST",
        data
      }),
      res => {
        context.commit(SET_ROLE_ACTION, res.data.obj);
      }
    );
  },
  [UPDATE_ROLE_ACTION](context, data) {
    return withErrorHandling(
      context,
      axios({
        url: `${baseUrl}roles/actions/${data.uuid}`,
        withCredentials: true,
        method: "PUT",
        data
      }),
      res => {
        context.commit(SET_ROLE_ACTION, res.data.obj);
      }
    );
  },
  [DELETE_ROLE_ACTION](context, actionId) {
    return withErrorHandling(
      context,
      axios({
        url: `${baseUrl}roles/actions/${actionId}`,
        withCredentials: true,
        method: "DELETE"
      }),
      res => {
        context.commit(REMOVE_ROLE_ACTION, actionId);
      }
    );
  }
};

const mutations = {
  [SET_ROLES](state, { type, roles }) {
    const newRoles = new Map(state.roles);
    newRoles.set(type, roles);
    state.roles = newRoles;
  },
  [SET_ROLE](state, role) {
    const newRoles = new Map(state.roles);

    const userRoles = state.roles.get(RoleTypes.USER_ROLE);
    let idx = userRoles.findIndex(eachRole => eachRole.uuid === role.uuid);
    if (idx === -1) {
      newRoles.set(RoleTypes.USER_ROLE, [role, ...userRoles]);
    } else {
      let roles = userRoles.slice();
      roles[idx] = role;
      newRoles.set(RoleTypes.USER_ROLE, roles);
    }
    state.roles = newRoles;
  },
  [REMOVE_ROLE](state, roleId) {
    const newRoles = new Map(state.roles);
    newRoles.set(
      RoleTypes.USER_ROLE,
      newRoles.get(RoleTypes.USER_ROLE).filter(role => role.uuid !== roleId)
    );
    state.roles = newRoles;
  },
  [SET_ROLE_ACTIONS](state, actions) {
    state.actions = actions;
  },
  [SET_ROLE_ACTION](state, action) {
    let idx = state.actions.findIndex(
      eachAction => eachAction.uuid === action.uuid
    );
    if (idx === -1) {
      state.actions = [action, ...state.actions];
    } else {
      let actions = state.actions.slice();
      actions[idx] = action;
      state.actions = actions;
    }
  },
  [REMOVE_ROLE_ACTION](state, actionId) {
    state.actions = state.actions.filter(action => action.uuid !== actionId);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
