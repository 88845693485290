import request from "../../utils/request";
import { ADD_TOAST_ACTION, REMOVE_TOAST_ACTION } from "./actions.type";
import { ADD_TOAST, REMOVE_TOAST } from "./mutations.type";
import { baseUrl } from "../../config/config";

const state = {
  toasts: []
};

const getters = {
  toasts(state) {
    return state.toasts;
  }
};

const actions = {
  //查找角色列表
  async roleList() {
    return await request({
      url: baseUrl + "/role/list",
      method: "GET"
    });
  },
  [ADD_TOAST_ACTION](context, toast) {
    context.commit(ADD_TOAST, toast);
  },
  [REMOVE_TOAST_ACTION](context, toast) {
    context.commit(REMOVE_TOAST, toast);
  }
};

const mutations = {
  [ADD_TOAST](state, toast) {
    state.toasts = [toast, ...state.toasts];
  },
  [REMOVE_TOAST](state, toast) {
    state.toasts = state.toasts.filter(eachToast => eachToast !== toast);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
