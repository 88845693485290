const OTHER_ACTION_RESOURCE = "other:*";

export function buildActionsByPrefix(namespaceActions) {
  const defaultActions = buildDefaultActions();
  const actionMap = {};

  const actionSet = new Set(namespaceActions);

  const addActionChildren = action => {
    // First try to add the actions for each child action.
    // E.g. "kitchens:facility:permit:*" should be considered before "kitchens:facility:*"
    action.children.forEach(child => addActionChildren(child));

    const actionPrefix = action.key.split("*")[0];
    actionSet.forEach(childAction => {
      if (childAction.action.startsWith(actionPrefix)) {
        action.children.push(getActionNode(childAction));
        actionSet.delete(childAction);
      }
    });
  };

  // For each action group, add the actions.
  defaultActions.forEach(action => {
    addActionChildren(action);
    actionMap[action.key] = action;
  });

  // Add the remaining in the other section
  actionSet.forEach(action => {
    actionMap[OTHER_ACTION_RESOURCE].children.push(getActionNode(action));
  });

  return actionMap;
}

export function enabledActions(allActions, roleActions) {
  const roleActionsSet = new Set(roleActions);

  const actionsByPrefix = buildActionsByPrefix(allActions);

  const addEnabledActions = action => {
    if (roleActionsSet.has(action.key)) {
      checkedKeys.push(action.key);
      return;
    }
    action.children.forEach(action => {
      addEnabledActions(action);
    });
  };

  const checkedKeys = [];
  Object.values(actionsByPrefix).forEach(resource => {
    addEnabledActions(resource);
  });

  return checkedKeys;
}

export function mergeActions(
  namespaceActions,
  existingRoleActions,
  updatedRoleActions
) {
  // No change
  if (!updatedRoleActions) {
    return existingRoleActions;
  }

  const actionsByPrefix = buildActionsByPrefix(namespaceActions);

  const updatedRoleActionsSet = new Set(updatedRoleActions);

  const mergedActions = [
    // We don't let the user configure these actions, so keep them.
    ...getUnmanagedActions(namespaceActions, existingRoleActions)
  ];

  const addResourceActions = (action, parentSelected) => {
    if (parentSelected) {
      updatedRoleActionsSet.delete(action.key);
    } else if (updatedRoleActionsSet.has(action.key)) {
      // Add action if it is enabled (e.g. "kitchens:facilities:opexbill:*")
      mergedActions.push(action.key);
      parentSelected = true;
    }
    action.children.forEach(child => addResourceActions(child, parentSelected));
  };

  Object.values(actionsByPrefix).forEach(action => {
    addResourceActions(action, false);
  });

  // Add the rest of the configured actions
  updatedRoleActionsSet.forEach(action => mergedActions.push(action));

  return mergedActions;
}

// build an action node for use in the ant-design-vue-tree
function getActionNode(action) {
  const description = action.description || "没有说明";
  return {
    ...action,
    title: `${action.name} (${description})`,
    key: action.action,
    children: []
  };
}

// Given a set of role actions, return the list of actions not managed by us.
function getUnmanagedActions(namespaceActions, roleActions) {
  const unmanagedActions = [];
  const namespaceActionSet = new Set([
    // Add the action for each namespace action we have ("kitchens:organization:store:edit")
    ...namespaceActions.map(namespaceAction => namespaceAction.action)
  ]);

  const addGroupedAction = action => {
    namespaceActionSet.add(action.key);
    action.children.forEach(childAction => addGroupedAction(childAction));
  };

  // Add the wildcard action for each of our grouped actions ("kitchens:organization:*")
  buildDefaultActions().forEach(groupedAction =>
    addGroupedAction(groupedAction)
  );

  roleActions.forEach(action => {
    if (!namespaceActionSet.has(action)) {
      unmanagedActions.push(action);
    }
  });

  return unmanagedActions;
}

function buildDefaultActions() {
  return [
    {
      key: "kitchens:facility:*",
      title: "门店",
      children: [
        {
          key: "kitchens:facility:propertylease:*",
          title: "承租合同",
          children: []
        },
        {
          key: "kitchens:facility:permit:*",
          title: "证照信息",
          children: []
        },
        {
          key: "kitchens:facility:fees:*",
          title: "门店费用",
          children: []
        },
        {
          key: "kitchens:facility:legalentity:*",
          title: "门店签约主体",
          children: []
        },
        {
          key: "kitchens:facility:paymentchannel:*",
          title: "店支付主体",
          children: []
        },
        {
          key: "kitchens:facility:order:*",
          title: "经营数据",
          children: []
        },
        {
          key: "kitchens:facility:opexbill:*",
          title: "经营费用账单",
          children: []
        },
        {
          key: "kitchens:facility:rentbill:*",
          title: "基础服务费账单",
          children: []
        },
        {
          key: "kitchens:facility:terminationbill:*",
          title: "退租结算账单",
          children: []
        },
        {
          key: "kitchens:facility:buildbill:*",
          title: "改造费用账单",
          children: []
        },
        {
          key: "kitchens:facility:downbill:*",
          title: "首期款账单",
          children: []
        },
        {
          key: "kitchens:facility:kitchen:*",
          title: "档口信息",
          children: []
        },
        {
          key: "kitchens:facility:staff:*",
          title: "门店人员",
          children: []
        },
        {
          key: "kitchens:facility:leasetermination:*",
          title: "退租申请",
          children: []
        },
        {
          key: "kitchens:facility:clientonboarding:*",
          title: "开业审批",
          children: []
        },
        {
          key: "kitchens:facility:repairs:*",
          title: "维修申请",
          children: []
        },
        {
          key: "kitchens:facility:meter:*",
          title: "水电燃表",
          children: []
        }
      ]
    },
    {
      key: "kitchens:organization:*",
      title: "组织",
      children: []
    },
    {
      key: "users:*",
      title: "用户",
      children: []
    },
    {
      key: "groups:*",
      title: "角色",
      children: []
    },
    {
      key: "css_china:fmsapp:*",
      title: "云厨宝APP",
      children: []
    },
    {
      key: "css_china:fmsportal:*",
      title: "FMS后台",
      children: []
    },
    {
      key: "css_china:pushconfig:*",
      title: "推送配置",
      children: []
    },
    {
      key: "css_china:appversion:*",
      title: "版本管理",
      children: []
    },
    {
      key: "css_china:statistics:*",
      title: "数据统计",
      children: []
    },
    {
      key: OTHER_ACTION_RESOURCE,
      title: "其他类别",
      children: []
    }
  ];
}

export const OrganizationActions = {
  // Brand
  BRAND_CREATE: "kitchens:organization:brand:create",
  BRAND_READ: "kitchens:organization:brand:read",
  BRAND_EDIT: "kitchens:organization:brand:edit",

  // Store
  STORE_CREATE: "kitchens:organization:store:create",
  STORE_READ: "kitchens:organization:store:read",
  STORE_EDIT: "kitchens:organization:store:edit",

  // Client
  CLIENT_READ: "kitchens:organization:client:read",
  CLIENT_EDIT: "kitchens:organization:client:edit",

  // Bill
  BILL_CREATE: "kitchens:organization:bill:create",
  BILL_READ: "kitchens:organization:bill:read",
  BILL_EDIT: "kitchens:organization:bill:edit",
  BILL_CONFIRM: "kitchens:organization:bill:confirm",

  // Contract
  CONTRACT_READ: "kitchens:organization:contract:read",
  CONTRACT_EDIT: "kitchens:organization:contract:edit",
  CONTRACT_CANCEL: "kitchens:organization:contract:cancel",
  // 上传/锁定合同
  CONTRACT_UPLOAD: "kitchens:organization:contract:upload",
  // 上传/锁定签约文档
  CONTRACT_SIGN_UPLOAD: "kitchens:organization:contractsign:upload",
  CONTRACT_BILL_EDIT: "kitchens:organization:rentbill:edit",
  // 同步合同to crm or lease agreement
  CONTRACT_SYNC: "kitchens:organization:contract:sync",
};

export const FacilityActions = {
  // Facility
  FACILITY_READ: "kitchens:facility:read",
  FACILITY_CREATE: "kitchens:facility:create",
  FACILITY_EDIT: "kitchens:facility:edit",

  // Lease termination
  LEASE_TERMINATION_CREATE: "kitchens:facility:leasetermination:create",
  LEASE_TERMINATION_READ: "kitchens:facility:leasetermination:read",

  // Property lease
  PROPERTY_LEASE_CREATE: "kitchens:facility:propertylease:create",
  PROPERTY_LEASE_READ: "kitchens:facility:propertylease:read",
  PROPERTY_LEASE_EDIT: "kitchens:facility:propertylease:edit",

  // Permit
  PERMIT_CREATE: "kitchens:facility:permit:create",
  PERMIT_READ: "kitchens:facility:permit:read",
  PERMIT_EDIT: "kitchens:facility:permit:edit",

  // Legal entity
  LEGAL_ENTITY_CREATE: "kitchens:facility:legalentity:create",
  LEGAL_ENTITY_READ: "kitchens:facility:legalentity:read",
  LEGAL_ENTITY_EDIT: "kitchens:facility:legalentity:edit",

  // Payment channel
  PAYMENT_CHANNEL_CREATE: "kitchens:facility:paymentchannel:create",
  PAYMENT_CHANNEL_READ: "kitchens:facility:paymentchannel:read",
  PAYMENT_CHANNEL_EDIT: "kitchens:facility:paymentchannel:edit",

  // Bill fees
  FEES_CREATE: "kitchens:facility:fees:create",
  FEES_READ: "kitchens:facility:fees:read",
  FEES_EDIT: "kitchens:facility:fees:edit",

  // Orders
  ORDER_EDIT: "kitchens:facility:order:edit",
  ORDER_CREATE: "kitchens:facility:order:create",
  ORDER_READ: "kitchens:facility:order:read",

  // OPEX Bill
  OPEX_BILL_CREATE: "kitchens:facility:opexbill:create",
  OPEX_BILL_READ: "kitchens:facility:opexbill:read",
  OPEX_BILL_SEND: "kitchens:facility:opexbill:send",
  OPEX_BILL_SEND_MANAGER: "kitchens:facility:opexbill:sendmanager",
  OPEX_BILL_ADJUST: "kitchens:facility:opexbill:adjust",

  // Rent bill
  RENT_BILL_READ: "kitchens:facility:rentbill:read",
  RENT_BILL_SEND: "kitchens:facility:rentbill:send",
  RENT_BILL_SEND_MANAGER: "kitchens:facility:rentbill:sendmanager",
  RENT_BILL_ADJUST: "kitchens:facility:rentbill:adjust",

  // Termination (settlement) bill
  TERMINATION_BILL_READ: "kitchens:facility:terminationbill:read",
  TERMINATION_BILL_SEND: "kitchens:facility:terminationbill:send",
  TERMINATION_BILL_ADJUST: "kitchens:facility:terminationbill:adjust",

  // build bill 维修改造费用账单
  BUILD_BILL_READ: "kitchens:facility:buildbill:read",

  // down payment bill 首期款账单
  DOWN_BILL_READ: "kitchens:facility:downbill:read",

  // Kitchens
  KITCHEN_CREATE: "kitchens:facility:kitchen:create",
  KITCHEN_READ: "kitchens:facility:kitchen:read",
  KITCHEN_EDIT: "kitchens:facility:kitchen:edit",
  KITCHEN_STATUS_EDIT: "kitchens:facility:kitchen:edit:edit_status",

  // Min price & standard price
  MIN_PRICE_READ: "kitchens:facility:kitchen:read:view_min_price",
  MIN_PRICE_EDIT: "kitchens:facility:kitchen:edit:edit_min_price",
  STANDARD_PRICE_READ: "kitchens:facility:kitchen:read:view_standard_price",
  STANDARD_PRICE_EDIT: "kitchens:facility:kitchen:edit:edit_standard_price",

  // Facility staff
  STAFF_READ: "kitchens:facility:staff:read",

  // Onboarding
  CREATE_ONBOARDING: "kitchens:facility:clientonboarding:create",
  READ_ONBOARDING: "kitchens:facility:clientonboarding:read",

  // Repairs
  CREATE_REPAIR: "kitchens:facility:repairs:create",
  READ_REPAIR: "kitchens:facility:repairs:read",

  // Meters
  METER_CREATE: "kitchens:facility:meter:create",
  METER_READ: "kitchens:facility:meter:read",
  METER_EDIT: "kitchens:facility:meter:edit",

  // Tenant entering
  TENANT_ENTERING_READ: "kitchens:facility:merchantentering:read",

  // down pay refund 入会费返还
  DOWN_PAY_REFUND_READ: "kitchens:facility:downpayrefund:read",

  // 换铺押金结算
  CHANGE_STORE_READ: "kitchens:facility:changestore:read",
  CHANGE_STORE_EDIT: "kitchens:facility:changestore:edit",

  // 续租押金结算
  CONTINUE_RENT_READ: "kitchens:facility:continuerent:read",
  CONTINUE_RENT_EDIT: "kitchens:facility:continuerent:edit",

  // 定金记录
  DEPOSIT_APPLY: "kitchens:facility:deposit:apply",
  DEPOSIT_ABOLISH: "kitchens:facility:deposit:abolish",

  // 商户余额相关
  LIST_LICENSEE_BALANCE: "kitchens:facility:licensee_balance:list",
  VIEW_LICENSEE_BALANCE_DETAIL: "kitchens:facility:licensee_balance:view_detail",

  // 水电余额相关
  LIST_UTILITY_BALANCE: "kitchens:facility:utility_balance:list",
  VIEW_UTILITY_BALANCE_DETAIL: "kitchens:facility:utility_balance:view_detail",
};

export const GroupActions = {
  GROUP_READ: "groups:read",
  GROUP_CREATE: "groups:create",
  GROUP_DELETE: "groups:delete",
  GROUP_UPDATE: "groups:update",
  GROUP_UPDATE_ACTIONS: "groups:update_policy",
  GROUP_USERS_READ: "groups:read_users",
  RESOURCE_NAMESPACE_READ: "resource_namespaces:read",
  RESOURCE_NAMESPACE_EDIT: "resource_namespaces:edit"
};

export const UserActions = {
  USER_CREATE: "users:create",
  USER_READ: "users:read",
  USER_UPDATE: "users:update",
  USER_UPDATE_PASSWORD: "users:update_password",
  GROUPS_READ: "users:read_groups"
};

export const AdminActions = {
  // Push config
  PUSH_CONFIG_CREATE: "css_china:pushconfig:create",
  PUSH_CONFIG_EDIT: "css_china:pushconfig:edit",
  PUSH_CONFIG_READ: "css_china:pushconfig:read",

  // Apps
  FMS_APP_LOGIN: "css_china:fmsapp:login",
  APP_VERSION_CREATE: "css_china:appversion:create",
  APP_VERSION_READ: "css_china:appversion:read",
  APP_VERSION_EDIT: "css_china:appversion:edit",
  FMS_PORTAL_LOGIN: "css_china:fmsportal:login",
  FMS_CSS_CONFIGURE: "css_china:css:configure"
};

Object.freeze(OrganizationActions);
Object.freeze(FacilityActions);
Object.freeze(AdminActions);
Object.freeze(GroupActions);
Object.freeze(UserActions);
