// roles
export const GET_ROLES = "getRoles";
export const CREATE_ROLE = "createRole";
export const UPDATE_ROLE = "updateRole";
export const DELETE_ROLE = "deleteRole";
export const GET_ROLE_ACTIONS = "getRoleActions";
export const CREATE_ROLE_ACTION = "createRoleAction";
export const UPDATE_ROLE_ACTION = "updateRoleAction";
export const DELETE_ROLE_ACTION = "deleteRoleAction";

// toasts
export const ADD_TOAST_ACTION = "addToast";
export const REMOVE_TOAST_ACTION = "removeToast";

// cities
export const GET_USER_CITIES = "getUserCities";
export const GET_CITIES = "getCities";
export const GET_CITY = "getCity";
export const UPSERT_CITY = "upsertCity";
export const DELETE_CITY = "deleteCity";

// users
export const GET_USERS = "getUsers";
export const GET_USER_DETAILS = "getUserDetails";
export const GET_AUTHENTICATED_USER_DETAILS = "getAuthenticatedUserDetails";
export const CREATE_USER = "createUser";
export const UPDATE_USER = "updateUser";
export const ENABLE_USER = "enableUser";
export const DISABLE_USER = "disableUser";
export const RESET_USER_PASSWORD = "resetUserPassword";

// user roles
export const ADD_ROLES_TO_USER = "addRolesToUser";
export const REMOVE_ROLES_FROM_USER = "removeRolesFromUser";

// facilities
export const GET_FACILITIES = "getFacilities";

// stores
export const GET_STORES = "getStores";
export const RESET_STORES = "resetStores";

// bills
export const GET_BILLS = "getBills";
export const RESET_BILLS = "resetBills";

// deposits
export const GET_DEPOSITS = "getDeposits";
export const RESET_DEPOSITS = "resetDeposits";