import Vue from "vue";
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

Vue.filter("NumberFormat", function(value) {
  if (!value) {
    return "-";
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); // 将整数部分逢三一断
  return intPartFormat;
});

Vue.filter("dayjs", function(dataStr, pattern = "YYYY-MM-DD HH:mm:ss") {
  return moment(dataStr).format(pattern);
});

Vue.filter("moment", function(dataStr, pattern = "YYYY-MM-DD HH:mm:ss") {
  return moment(dataStr).format(pattern);
});

Vue.filter("currency", function(strVal, prefix = "¥") {
  if (strVal === undefined || strVal === null) {
    return "0";
  }

  return prefix + Number.parseInt(strVal).toLocaleString();
});

Vue.filter("nullsafe", function(target, path = "", def = "-") {
  const ret = [target, ...path.split(".")].reduce((v, prop) =>
    v ? v[prop] : undefined
  );
  if (ret === undefined) {
    return def;
  }

  return ret;
});

Vue.filter("percent", function(val) {
  let ret = Number.parseFloat(val);
  ret = Number.isNaN(ret) ? val : (ret * 100).toFixed(1);
  return ret + "%";
});

Vue.filter("shortNumber", function(val) {
  if (val >= 1000000) {
    return `${(val / 1000000).toFixed(1)}m`;
  } else if (val >= 1000) {
    return `${(val / 1000).toFixed(1)}k`;
  } else {
    return val;
  }
});

// 获取url中的文件名
Vue.filter("urlFileName", function(val) {
  let arr = val.split('/');
  if(arr.length > 1) {
    return arr[arr.length - 1];
  } else {
    return arr[0];
  }
});
