import Vue from "vue";
import VueI18n from "vue-i18n";
import App from "./App.vue";
import router from "./router/router";
import store from "./store/index.js";
import enUS from "./locale/enUS";
import zhCN from "./locale/zhCN";

import "./utils/filter"; // global filter

import queryString from "query-string";
import VueHighlightJS from "vue-highlightjs";
import "ant-design-vue/dist/antd.css";
import "./styles/common.less";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Viewer from "v-viewer";
import "viewerjs/dist/viewer.css";
import axios from "axios";
import { baseUrl } from "./config/config";
import { endpoints } from "./config/endpoints"

import {
  Button,
  Layout,
  Icon,
  Drawer,
  Radio,
  Menu,
  Form,
  Input,
  Select,
  ConfigProvider,
  Dropdown,
  DatePicker,
  Badge,
  Tabs,
  Spin,
  List,
  Avatar,
  Tooltip,
  Tag,
  Checkbox,
  Col,
  Row,
  Popover,
  Progress,
  Alert,
  Card,
  Table,
  Upload,
  Modal,
  Notification,
  Carousel,
  // Confirm,
  Popconfirm,
  message,
  Steps,
  Collapse,
  Divider,
  TimePicker,
  Pagination,
  Breadcrumb,
  Tree,
  TreeSelect,
} from "ant-design-vue";
import Authorized from "./components/Authorized";
import Auth from "./directives/auth";
import "highlight.js/styles/github.css";
import ref from "vue-ref";
Vue.use(ref, { name: "ant-ref" });

// FormItem中的decoratorOption方法有bug，已提交pr到ant-design-vue
// 47行到73行为临时解决方案
const FormItem = Form.Item;
import find from "lodash/find";
import warning from "ant-design-vue/es/_util/warning";
FormItem.methods.decoratorOption = vnode => {
  if (vnode.data && vnode.data.directives) {
    const directive = find(vnode.data.directives, ["name", "decorator"]);
    warning(
      !directive || (directive && Array.isArray(directive.value)),
      `Invalid directive: type check failed for directive "decorator". Expected Array, got ${typeof (directive // directive可能为undefined
        ? directive.value
        : directive)}. At ${vnode.tag}.`
    );
    return directive ? directive.value : null;
  } else {
    return null;
  }
};

Vue.config.productionTip = false;

Vue.use(Viewer);
Vue.use(Button);
Vue.use(Layout);
Vue.use(Icon);
Vue.use(Drawer);
Vue.use(Radio);
Vue.use(Menu);
Vue.use(Form);
Vue.use(Input);
Vue.use(Select);
Vue.use(ConfigProvider);
Vue.use(Dropdown);
Vue.use(DatePicker);
Vue.use(Avatar);
Vue.use(Tooltip);
Vue.component("Authorized", Authorized);
Vue.use(Auth);
Vue.use(VueI18n);
Vue.use(VueHighlightJS);
Vue.use(Badge);
Vue.use(Tabs);
Vue.use(Spin);
Vue.use(List);
Vue.use(Tag);
Vue.use(Checkbox);
Vue.use(Col);
Vue.use(Row);
Vue.use(Card);
Vue.use(Table);
Vue.use(Modal);
Vue.use(Upload);
Vue.use(Popover);
Vue.use(Progress);
Vue.use(Alert);
Vue.use(Notification);
Vue.use(Popconfirm);
Vue.use(Breadcrumb);
Vue.use(Steps);
Vue.use(Collapse);
Vue.use(Divider);
Vue.use(TimePicker);
Vue.use(Pagination);
Vue.use(Carousel);
Vue.use(Tree);
Vue.use(TreeSelect);
Vue.use(VueQuillEditor);
Vue.prototype.$message = message;
Notification.config({
  placement: "topRight",
  // bottom: '50px',
  duration: 3
});

const i18n = new VueI18n({
  locale: queryString.parse(location.search).locale || "zhCN",
  messages: {
    zhCN: { message: zhCN },
    enUS: { message: enUS }
  }
});

const IconFont = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_1154049_w87h4oeytph.js" // 在 iconfont.cn 上生成
});

Vue.component("IconFont", IconFont);

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount("#app");

Vue.prototype.resetSetItem = function(key, newVal) {
  var newStorageEvent = document.createEvent("StorageEvent");
  const storage = {
    setItem: function(k, val) {
      // sessionStorage.setItem(k, val);

      // 初始化创建的事件
      newStorageEvent.initStorageEvent(
        "setItem",
        false,
        false,
        k,
        null,
        val,
        null,
        null
      );

      // 派发对象
      window.dispatchEvent(newStorageEvent);
    }
  };
  return storage.setItem(key, newVal);
};

Vue.prototype.$baseUrl = baseUrl;
Vue.prototype.$http = axios;
Vue.prototype.$endpoints = endpoints;