import { requirePermission } from "../utils/auth";
import { AdminActions, FacilityActions } from "../utils/actions";
import basic from "../assets/basic.png";

const BASIC_CONFIGURATION_ROUTES = {
  path: "/basicConfiguration",
  name: "basicConfiguration",
  component: { render: h => h("router-view") },
  redirect: "/basicConfiguration/cityConfigure",
  meta: { title: "基础配置", icon: basic, showInMenu: true },
  children: [
    {
      path: "/basicConfiguration/appVersion",
      name: "appVersion",
      component: () => import("@/views/basicConfiguration/appVersion"),
      meta: {
        title: "APP版本管理",
        showInMenu: true,
        authorizationConfig: requirePermission(AdminActions.APP_VERSION_READ)
      }
    },
    {
      path: "/basicConfiguration/hydropowerConfigure",
      name: "hydropowerConfigure",
      component: () =>
        import("@/views/basicConfiguration/hydropowerConfigureNewPage"),
      meta: {
        title: "水电燃表管理",
        keepAlive: true,
        showInMenu: true,
        authorizationConfig: requirePermission(FacilityActions.METER_READ)
      }
    },
    {
      path: "/basicConfiguration/hydropowerConfigure/hydropowerConfigureDetail",
      name: "hydropowerConfigureDetail",
      component: () =>
        import("@/views/basicConfiguration/hydropowerConfigureDetail"),
      meta: {
        title: "水电燃表详情",
        authorizationConfig: requirePermission(FacilityActions.METER_READ)
      }
    },
    {
      path: "/basicConfiguration/hydropowerConfigure/hydropowerConfigureNew",
      name: "hydropowerConfigureNew",
      component: () =>
        import(
          "@/views/basicConfiguration/hydropowerDetail/hydropowerConfigureNew"
        ),
      meta: {
        title: "水电燃表新增",
        authorizationConfig: requirePermission(FacilityActions.METER_CREATE)
      }
    },
    {
      path: "/basicConfiguration/hydropowerConfigure/hydropowerConfigureEdit",
      name: "hydropowerConfigureEdit",
      component: () =>
        import(
          "@/views/basicConfiguration/hydropowerDetail/hydropowerConfigureEdit"
        ),
      meta: {
        title: "水电燃表编辑",
        authorizationConfig: requirePermission(FacilityActions.METER_EDIT)
      }
    },
    {
      path: "/basicConfiguration/cssConfigure",
      name: "cssSwitch",
      component: () => import("@/views/basicConfiguration/cssConfigure"),
      meta: {
        title: "CSS设置",
        showInMenu: true,
        authorizationConfig: requirePermission(AdminActions.FMS_CSS_CONFIGURE)
      }
    },
  ]
};

export default BASIC_CONFIGURATION_ROUTES;
