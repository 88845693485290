import { unauthenticated } from "../utils/auth";

const USER_ROUTES = {
  path: "/user",
  component: () =>
    import(/* webpackChunkName: "layout" */ "../layouts/UserLayout"),
  meta: { authorizationConfig: unauthenticated() },
  children: [
    {
      path: "/user",
      redirect: "/user/login",
      meta: { authorizationConfig: unauthenticated() }
    },
    {
      path: "/user/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "user" */ "../views/User/Login"),
      meta: { authorizationConfig: unauthenticated() }
    }
  ]
};

export default USER_ROUTES;
