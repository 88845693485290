// roles
export const SET_ROLES = "setRoles";
export const SET_ROLE = "setRole";
export const REMOVE_ROLE = "removeRole";

// role actions
export const SET_ROLE_ACTIONS = "setRoleActions";
export const SET_ROLE_ACTION = "setRoleAction";
export const REMOVE_ROLE_ACTION = "removeRoleAction";

// toasts
export const ADD_TOAST = "addToast";
export const REMOVE_TOAST = "removeToast";

// cities
export const SET_USER_CITIES = "setUserCities";
export const SET_CITIES = "setCities";
export const SET_CITY = "setCity";
export const REMOVE_CITY = "removeCity";

// users
export const SET_USERS = "setUsers";
export const SET_USER_LOADED = "setUserLoaded";
export const SET_USER = "setUser";
export const SET_USER_ROLES = "setUserRoles";
export const UPDATE_USER_STATUS = "updateUserStatus";

// user roles
export const ADD_USER_ROLES = "addUserRoles";
export const REMOVE_USER_ROLES = "removeUserRoles";

// facilities
export const SET_FACILITIES = "setFacilities";

// stores
export const SET_STORES = "setStores";
export const CLEAR_STORES = "clearStores";

// bills
export const SET_BILLS = "setBills";
export const CLEAR_BILLS = "clearBills";

// deposits
export const SET_DEPOSITS = "setDeposits";
export const CLEAR_DEPOSITS = "clearDeposits";