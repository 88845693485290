import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { isAuthenticated } from "../utils/auth";
import USER_ROUTES from "./user";
import FACILITY_ROUTES from "./facility";
import KITCHEN_ROUTES from "./kitchen";
import ORGANIZATION_ROUTES from "./organization";
import FINANCE_ROUTES from "./finance";
import AUTHORIZATION_ROUTES from "./authorization";
import PUSH_ROUTES from "./push";
import BASIC_CONFIGURATION_ROUTES from "./configuration";
import STATISTICS_ROUTES from "./statistics";
Vue.use(Router);

const nineDays = 9 * 24 * 3600 * 1000;

const router = new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/store/storeList",
      meta: {},
      component: () =>
        import(/* webpackChunkName: "layout" */ "../layouts/BasicLayout"),
      children: [
        // organizations
        ORGANIZATION_ROUTES,
        // store
        FACILITY_ROUTES,
        // kitchen/stalls
        KITCHEN_ROUTES,
        // finance
        FINANCE_ROUTES,
        // push
        PUSH_ROUTES,
        // basic configuration
        BASIC_CONFIGURATION_ROUTES,
        // authorization
        AUTHORIZATION_ROUTES,
        // Statistics
        STATISTICS_ROUTES
      ]
    },
    USER_ROUTES,
    {
      path: "/403",
      name: "403",
      component: () =>
        import(
          /* webpackChunkName: "exception" */ "@/views/Exception/Unauthorized"
        )
    },
    {
      path: "*",
      name: "404",
      component: () =>
        import(/* webpackChunkName: "exception" */ "@/views/Exception/404")
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (!isAuthenticated() && to.path !== "/user/login") {
    next( "/user/login" );
  } else if(isAuthenticated() && to.path !== "/user/login" && Date.now() > Number(localStorage.getItem("tokenDate")) + nineDays ) {
    // token generated in mundo have a expiry of 10 days, so if 9 days passed, let user login again. 
    next( "/user/login");
  } else  {
    if (to.path !== from.path) {
      NProgress.start();
    }
    next();
  }
});

router.afterEach(() => {
  NProgress.done();
});
export default router;
