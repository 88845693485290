import axios from "axios";
import { GET_BILLS, RESET_BILLS } from "./actions.type";
import { SET_BILLS, CLEAR_BILLS } from "./mutations.type";
import { baseUrl } from "@/config/config";
import { withErrorHandling } from "./utils";

const state = {
  pagedBills: new Map(),
  tokens: new Map(),
  nextToken: null,
  total: 0

};

const getters = {
  bills(state) {
    return pageNum => {
      return state.pagedBills.get(pageNum);
    };
  },
  total(state) {
    return state.total;
  },
  nextToken(state) {
    return state.nextToken;
  }
};

const actions = {
  [GET_BILLS](context, params) {
    if (state.pagedBills.has(params.page)) {
      return Promise.resolve();
    }
    return withErrorHandling(
      context,
      axios({
        url: baseUrl + "bill/list",
        method: "GET",
        params: {
          ...params,
          nextToken: state.tokens.get(params.page)
        }
      }),
      res =>
        context.commit(SET_BILLS, {
          data: res.data,
          page: params.page
        })
    );
  },
  [RESET_BILLS](context) {
    context.commit(CLEAR_BILLS);
  }
};

const mutations = {
  [SET_BILLS](state, params) {
    const newPagedBills = new Map(state.pagedBills);
    newPagedBills.set(params.page, params.data.rows);
    state.pagedBills = newPagedBills;

    const newTokens = new Map(state.tokens);
    state.nextToken = params.data.nextToken;
    newTokens.set(params.page + 1, params.data.nextToken);
    state.tokens = newTokens;

    state.total = params.data.count;
  },
  [CLEAR_BILLS](state) {
    state.pagedBills = new Map();
    state.tokens = new Map();
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
