import axios from "axios";
import {
  ADD_ROLES_TO_USER,
  CREATE_USER,
  DISABLE_USER,
  ENABLE_USER,
  GET_AUTHENTICATED_USER_DETAILS,
  GET_USER_DETAILS,
  GET_USERS,
  REMOVE_ROLES_FROM_USER,
  RESET_USER_PASSWORD,
  UPDATE_USER
} from "./actions.type";
import {
  ADD_USER_ROLES,
  REMOVE_USER_ROLES,
  SET_USER,
  SET_USER_LOADED,
  SET_USER_ROLES,
  SET_USERS,
  UPDATE_USER_STATUS
} from "./mutations.type";
import { baseUrl } from "../../config/config";
import { addFailureToast, addSuccessToast, withErrorHandling } from "./utils";
import { RoleTypes } from "./roles";
import { getAuthenticatedUserId } from "../../utils/user";

const state = {
  pagedUsers: new Map(),
  users: new Map(),
  userRoles: new Map(),
  loadedUsers: new Set(),
  total: 0
};

const getters = {
  usersPaged: state => {
    return pageNum => {
      const users = state.pagedUsers.get(pageNum) || [];
      return users.map(id => state.users.get(id.toString()));
    };
  },
  user: state => {
    return id => {
      const userRoles = state.userRoles.get(id.toString()) || {};
      return {
        user: state.users.get(id.toString()),
        roles: userRoles,
        actions: new Set(
          (userRoles[RoleTypes.USER_ROLE] || []).flatMap(role => role.actions)
        )
      };
    };
  },
  authenticatedUser: state => {
    return getters.user(state)(getAuthenticatedUserId());
  },
  total: state => state.total
};

const actions = {
  [GET_USERS](context, params) {
    return withErrorHandling(
      context,
      axios({
        url: baseUrl + "users",
        withCredentials: true,
        method: "GET",
        params: params
      }),
      res => {
        context.commit(SET_USERS, {
          page: params.page,
          users: res.data.rows,
          total: res.data.count
        });
      }
    );
  },
  [GET_AUTHENTICATED_USER_DETAILS](context) {
    return context.dispatch(GET_USER_DETAILS, getAuthenticatedUserId());
  },
  [GET_USER_DETAILS](context, id) {
    if (state.loadedUsers.has(id.toString())) {
      return Promise.resolve();
    }
    return Promise.all([
      axios({
        url: baseUrl + "users/" + id,
        withCredentials: true,
        method: "GET"
      }),
      axios({
        url: baseUrl + "users/" + id + "/roles",
        withCredentials: true,
        method: "GET",
        params: {
          nextToken: "",
          rows: 100,
          roleType: RoleTypes.USER_ROLE
        }
      }),
      axios({
        url: baseUrl + "users/" + id + "/roles",
        withCredentials: true,
        method: "GET",
        params: {
          nextToken: "",
          rows: 100,
          roleType: RoleTypes.CITY
        }
      }),
      axios({
        url: baseUrl + "users/" + id + "/roles",
        withCredentials: true,
        method: "GET",
        params: {
          nextToken: "",
          rows: 100,
          roleType: RoleTypes.FACILITY
        }
      })
    ])
      .then(res => {
        let userLoaded = true;
        if (res[0].data.success) {
          context.commit(SET_USER, res[0].data.obj);
        } else {
          addFailureToast(context, `${res[0].data.returnMsg}`);
          userLoaded = false;
        }
        if (res[1].data.success) {
          context.commit(SET_USER_ROLES, {
            userId: id,
            roles: res[1].data.rows,
            type: RoleTypes.USER_ROLE
          });
        } else {
          addFailureToast(context, `${res[1].data.returnMsg}`);
          userLoaded = false;
        }
        if (res[2].data.success) {
          context.commit(SET_USER_ROLES, {
            userId: id,
            roles: res[2].data.rows,
            type: RoleTypes.CITY
          });
        } else {
          addFailureToast(context, `${res[2].data.returnMsg}`);
          userLoaded = false;
        }
        if (res[3].data.success) {
          context.commit(SET_USER_ROLES, {
            userId: id,
            roles: res[3].data.rows,
            type: RoleTypes.FACILITY
          });
        } else {
          addFailureToast(context, `${res[3].data.returnMsg}`);
          userLoaded = false;
        }
        context.commit(SET_USER_LOADED, { id, userLoaded });
      })
      .catch(e => {
        addFailureToast(context, `${e}`);
      });
  },
  [CREATE_USER](context, data) {
    return withErrorHandling(
      context,
      axios({
        url: baseUrl + "users",
        withCredentials: true,
        method: "POST",
        data: data
      }),
      res => {
        context.commit(SET_USER, res.data.obj);
        addSuccessToast(context, "用户已建立");
      }
    );
  },
  [UPDATE_USER](context, data) {
    return withErrorHandling(
      context,
      axios({
        url: baseUrl + "users/" + data.id,
        withCredentials: true,
        method: "PUT",
        data: data
      }),
      res => {
        context.commit(SET_USER, res.data.obj);
        addSuccessToast(context, "用户编辑成功");
      }
    );
  },
  [ENABLE_USER](context, data) {
    return withErrorHandling(
      context,
      axios({
        url: baseUrl + "users/" + data.id + "/enable",
        withCredentials: true,
        method: "POST",
        data: data
      }),
      res => {
        context.commit(UPDATE_USER_STATUS, {
          status: 1,
          id: data.id
        });
      }
    );
  },
  [DISABLE_USER](context, data) {
    return withErrorHandling(
      context,
      axios({
        url: baseUrl + "users/" + data.id + "/disable",
        withCredentials: true,
        method: "POST",
        data: data
      }),
      () => {
        context.commit(UPDATE_USER_STATUS, {
          status: 2,
          id: data.id
        });
      }
    );
  },
  [RESET_USER_PASSWORD](context, data) {
    return withErrorHandling(
      context,
      axios({
        url: baseUrl + "users/" + data.id + "/resetPassword",
        withCredentials: true,
        method: "POST",
        data: {}
      }),
      res => addSuccessToast(context, `密码已重置为默认密码：${res.data.obj}`)
    );
  },
  [ADD_ROLES_TO_USER](context, data) {
    return withErrorHandling(
      context,
      axios({
        url: `${baseUrl}users/${data.userId}/roles/add`,
        withCredentials: true,
        method: "POST",
        data: data
      }),
      () => {
        context.commit(ADD_USER_ROLES, data);
      }
    );
  },
  [REMOVE_ROLES_FROM_USER](context, data) {
    console.log("REMOVE_ROLES_FROM_USER", data);
    return withErrorHandling(
      context,
      axios({
        url: `${baseUrl}users/${data.userId}/roles/remove`,
        withCredentials: true,
        method: "POST",
        data: data
      }),
      () => {
        context.commit(REMOVE_USER_ROLES, data);
      }
    );
  }
};

const mutations = {
  [SET_USERS](state, { page, users, total }) {
    const newPagedUsers = new Map(state.pagedUsers);
    newPagedUsers.set(
      page,
      users.map(user => user.id.toString())
    );
    state.pagedUsers = newPagedUsers;

    state.total = total;

    const newUsers = new Map(state.users);
    users.forEach(user => newUsers.set(user.id.toString(), user));
    state.users = newUsers;
  },
  [SET_USER](state, user) {
    if (!state.users.has(user.id.toString())) {
      state.total += 1;
    }

    const newUsers = new Map(state.users);
    newUsers.set(user.id.toString(), user);
    state.users = newUsers;
  },
  [SET_USER_ROLES](state, { userId, roles, type }) {
    const newUserRoles = new Map(state.userRoles);

    const userRoles = {
      ...(state.userRoles.get(userId.toString()) || {})
    };
    userRoles[type] = roles;

    newUserRoles.set(userId.toString(), userRoles);
    state.userRoles = newUserRoles;
  },
  [UPDATE_USER_STATUS](state, { id, status }) {
    const newUsers = new Map(state.users);

    const user = state.users.get(id.toString());
    newUsers.set(user.id.toString(), {
      ...user,
      status: status
    });
    state.users = newUsers;
  },
  [ADD_USER_ROLES](state, { userId, roleIds, roles, type }) {
    const newUserRoles = new Map(state.userRoles);
    const existingUserRoles = state.userRoles.get(userId.toString()) || {};

    const userRoles = {
      ...existingUserRoles
    };
    userRoles[type] = [
      ...(existingUserRoles[type] || []),
      ...roleIds.map(roleId =>
        roles.get(type).find(role => role.uuid === roleId)
      )
    ];

    newUserRoles.set(userId.toString(), userRoles);
    state.userRoles = newUserRoles;
  },
  [REMOVE_USER_ROLES](state, { userId, roleIds, type }) {
    const newUserRoles = new Map(state.userRoles);
    const existingUserRoles = state.userRoles.get(userId.toString());

    const userRoles = {
      ...existingUserRoles
    };
    userRoles[type] = existingUserRoles[type].filter(
      existingRole => !roleIds.includes(existingRole.uuid)
    );

    newUserRoles.set(userId.toString(), userRoles);
    state.userRoles = newUserRoles;
  },
  [SET_USER_LOADED](context, { userLoaded, id }) {
    const newLoadedUsers = new Set(state.loadedUsers);
    if (userLoaded) {
      newLoadedUsers.add(id.toString());
    } else {
      newLoadedUsers.delete(id.toString());
    }
    state.loadedUsers = newLoadedUsers;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
