import { requirePermission } from "../utils/auth";
import { AdminActions } from "../utils/actions";
import opera from "../assets/opera.png";

const PUSH_ROUTES = {
  path: "/operate",
  name: "operate",
  component: { render: h => h("router-view") },
  redirect: "/operate/operatePush",
  meta: {
    title: "运营配置",
    showInMenu: true,
    icon: opera
  },
  children: [
    {
      path: "/operate/operatePush",
      name: "operatePush",
      component: () => import("@/views/operate/operatePush"),
      meta: {
        title: "推送配置",
        keepAlive: true,
        showInMenu: true,
        authorizationConfig: requirePermission(AdminActions.PUSH_CONFIG_READ)
      }
    },
    {
      path: "/operate/operatePush/operatePushNew",
      name: "operatePushNew",
      component: () =>
        import("@/views/operate/operatePushDetail/operatePushNew"),
      meta: {
        title: "新建推送",
        authorizationConfig: requirePermission(AdminActions.PUSH_CONFIG_CREATE)
      }
    },
    {
      path: "/operate/operatePush/operatePushDetail",
      name: "operatePushDetail",
      component: () =>
        import("@/views/operate/operatePushDetail/operatePushDetail"),
      meta: {
        title: "新建推送",
        authorizationConfig: requirePermission(AdminActions.PUSH_CONFIG_READ)
      }
    }
  ]
};

export default PUSH_ROUTES;
