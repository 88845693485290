import axios from "axios";
import { GET_DEPOSITS, RESET_DEPOSITS } from "./actions.type";
import { SET_DEPOSITS, CLEAR_DEPOSITS } from "./mutations.type";
import { baseUrl } from "@/config/config";
import { withErrorHandling } from "./utils";

const state = {
  pagedDeposits: new Map(),
  tokens: new Map(),
  nextToken: null,
  total: 0

};

const getters = {
  deposits(state) {
    return pageNum => {
      return state.pagedDeposits.get(pageNum);
    };
  },
  total(state) {
    return state.total;
  },
  nextToken(state) {
    return state.nextToken;
  }
};

const actions = {
  [GET_DEPOSITS](context, params) {
    if (state.pagedDeposits.has(params.page)) {
      return Promise.resolve();
    }
    return withErrorHandling(
      context,
      axios({
        url: baseUrl + "deposit/bill/list",
        method: "GET",
        params: {
          ...params,
          nextToken: state.tokens.get(params.page)
        }
      }),
      res =>
        context.commit(SET_DEPOSITS, {
          data: res.data,
          page: params.page
        })
    );
  },
  [RESET_DEPOSITS](context) {
    context.commit(CLEAR_DEPOSITS);
  }
};

const mutations = {
  [SET_DEPOSITS](state, params) {
    const newPagedDeposits = new Map(state.pagedDeposits);
    newPagedDeposits.set(params.page, params.data.rows);
    state.pagedDeposits = newPagedDeposits;

    const newTokens = new Map(state.tokens);
    state.nextToken = params.data.nextToken;
    newTokens.set(params.page + 1, params.data.nextToken);
    state.tokens = newTokens;

    state.total = params.data.count;
  },
  [CLEAR_DEPOSITS](state) {
    state.pagedDeposits = new Map();
    state.tokens = new Map();
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
