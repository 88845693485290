import axios from "axios";
import {
  DELETE_CITY,
  GET_CITIES,
  GET_CITY,
  GET_USER_CITIES,
  UPSERT_CITY
} from "./actions.type";
import {
  REMOVE_CITY,
  SET_CITIES,
  SET_CITY,
  SET_USER_CITIES
} from "./mutations.type";
import { baseUrl } from "../../config/config";
import { addSuccessToast, withErrorHandling } from "./utils";

const state = {
  userCities: [],
  pagedCities: new Map(),
  cities: new Map(),
  total: 0
};

const getters = {
  userCities(state) {
    return state.userCities.slice();
  },
  userCitiesAll(state) {
    return [{ id: 0, name: "全部" }, ...state.userCities];
  },
  citiesPaged: state => {
    return pageNum => {
      const cities = state.pagedCities.get(pageNum) || [];
      return cities.map(id => state.cities.get(id));
    };
  },
  city: state => {
    return id => state.cities.get(id);
  },
  total(state) {
    return state.total;
  }
};

const actions = {
  [GET_USER_CITIES](context) {
    if (state.userCities.length) {
      return Promise.resolve();
    }
    return withErrorHandling(
      context,
      axios({
        url: baseUrl + "city/listLoginCity",
        withCredentials: true,
        method: "GET"
      }),
      res => context.commit(SET_USER_CITIES, res.data.rows)
    );
  },
  [GET_CITIES](context, params) {
    if (state.pagedCities.has(params.page)) {
      return Promise.resolve();
    }
    return withErrorHandling(
      context,
      axios({
        url: baseUrl + "city/list",
        withCredentials: true,
        method: "GET",
        params: params
      }),
      res =>
        context.commit(SET_CITIES, {
          page: params.page,
          cities: res.data.rows,
          total: res.data.count
        })
    );
  },
  [GET_CITY](context, cityId) {
    if (state.cities.has(cityId)) {
      return Promise.resolve();
    }
    return withErrorHandling(
      context,
      axios({
        url: baseUrl + "city/" + cityId,
        withCredentials: true,
        method: "GET"
      }),
      res => context.commit(SET_CITY, res.data.obj)
    );
  },
  [UPSERT_CITY](context, data) {
    return withErrorHandling(
      context,
      axios({
        url: baseUrl + "city/edit",
        withCredentials: true,
        method: "POST",
        data: data
      }),
      res => {
        context.commit(SET_CITY, res.data.obj);
        addSuccessToast(context, "城市编辑成功");
      }
    );
  },
  [DELETE_CITY](context, data) {
    return withErrorHandling(
      context,
      axios({
        url: baseUrl + "city/delete",
        method: "POST",
        data: data
      }),
      res => {
        context.commit(REMOVE_CITY, res.data.obj);
        addSuccessToast(context, "城市删除成功");
      }
    );
  }
};

const mutations = {
  [SET_USER_CITIES](state, userCities) {
    state.userCities = userCities;
  },
  [SET_CITIES](state, { page, cities, total }) {
    const newPagedCities = new Map(state.pagedCities);
    newPagedCities.set(
      page,
      cities.map(city => city.id)
    );
    state.pagedCities = newPagedCities;

    state.total = total;

    const newCities = new Map(state.cities);
    cities.forEach(city => newCities.set(city.id, city));
    state.cities = newCities;
  },
  [SET_CITY](state, city) {
    if (!state.cities.has(city.id)) {
      state.pagedCities = new Map();
      state.total += 1;
    }

    const newCities = new Map(state.cities);
    newCities.set(city.id, city);
    state.cities = newCities;
  },
  [REMOVE_CITY](state, cityId) {
    const newCities = new Map(state.cities);
    newCities.delete(cityId);
    state.cities = newCities;
    state.pagedCities = new Map();
    state.total -= 1;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
