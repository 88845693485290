/*
Use this file to aggregate all the endopints used by fms web app.
Two set of endpoints will be used:
 - endpoints from FMS services, these are from the legacy FMS services, and will be deprecated and migrate to Ottergate gradually over time.
 - endpoints from Ottergate, new services will be exposed throught endpoints in Ottergate.
*/

import { fmsBaseUrl, ogBaseUrl } from "./config"

let endpoints = {
    UTILITY_LIST_WEG_METER_STATUS: fmsBaseUrl + "weg/list", // list utility meter info
    UTILITY_EXPORT_WEG_METER_STATUS: fmsBaseUrl + "weg/exportWegMeterStatus",
    UTILITY_GET_WEG_METER_DETAIL: fmsBaseUrl + "weg/detail",
    UTILITY_LIST_WEG_METER_READINGS: fmsBaseUrl + "weg/reading/list",

    CONTRACT_GET_CONTRACT_DETAIL: fmsBaseUrl + "contract/query/detail",

    FACILITY_LIST_FACILITY_BY_CITY_AND_BRAND: fmsBaseUrl + "facility/queryFacilitySelectByIds",

    // 获取多个商户的商户余额列表
    FINANCE_LIST_LICENSEE_BALANCE: fmsBaseUrl + "finance/balance/listLicenseeBalance",
    // 获取多个商户的水电余额列表
    FINANCE_LIST_UTILITY_BALANCE: fmsBaseUrl + "finance/balance/listUtilityBalance",
    // 导出多个商户的商户余额列表
    FINANCE_EXPORT_LICENSEE_CURRENT_BALANCE: fmsBaseUrl + "finance/balance/exportLicenseeCurrentBalance",
    // 导出多个商户的水电余额列表
    FINANCE_EXPORT_UTILITY_CURRENT_BALANCE: fmsBaseUrl + "finance/balance/exportUtilityBalanceByDate",
    // 导出多个商户的商户余额交易记录
    FINANCE_EXPORT_LICENSEE_BALANCE_TRANSACTION_HISTORY: fmsBaseUrl + "finance/balance/exportLicenseeBalanceTransactionHistory",
    // 导出多个商户的商户余额和水电余额交易记录
    FINANCE_EXPORT_UTILITY_BALANCE_TRANSACTION_HISTORY: fmsBaseUrl + "finance/balance/exportUtilityBalanceTransactionHistory",
    // 获取给定合同对应商户的商户余额交易记录
    FINANCE_GET_LICENSEE_BALANCE_TRANSACTION_HISTORY: fmsBaseUrl + "finance/balance/getLicenseeBalanceTransactionHistory",
    // 获取商户的水电余额充值记录
    FINANCE_GET_UTILITY_BALANCE_ADD_VALUE_ORDERS: fmsBaseUrl + "utilities/prepay/balance/recharge/orders",
    // 获取商户的水电余额扣费记录
    FINANCE_GET_UTILITY_BALANCE_DEDUCTION_RECORDS: fmsBaseUrl + "utilities/meters/records",
    // 获取给定合同对应商户的水电余额变动历史记录
    FINANCE_GET_UTILITY_BALANCE_TRANSACTION_HISTORY: fmsBaseUrl + "utilities/prepay/balance/changelogs",
    // 根据合同号获取水电预付费账号相关信息
    OG_ACCOUNT_GET_UTILITY_PREPAY_ACCOUNT_INFO: fmsBaseUrl + "utilities/prepay/balance/account/list",
    // 给定合同号，创建水电预付费账号
    OG_ACCOUNT_CREATE_UTILITY_BALANCE_ACCOUNT: fmsBaseUrl + "utilities/prepay/balance/account",
    // 开通水电预付费模式
    OG_ACCOUNT_ENABLE_UTILITY_BALANCE_ACCOUNT: fmsBaseUrl + "utilities/prepay/balance/account/enable",
};

export { endpoints };