<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <router-view></router-view>
    </a-config-provider>
    <toast-handler />
  </div>
</template>
<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import enUS from "ant-design-vue/lib/locale-provider/en_US";
import moment from "moment";
import "moment/locale/zh-cn";
import ToastHandler from "./components/Toast/ToastHandler";
export default {
  components: { ToastHandler },
  data() {
    return {
      locale: zhCN,
      // 超时计时器
      timInter: null,
      timeOut: 1000 * 60 * 60 * 24 // 一小时
    };
  },
  watch: {
    "$route.query.locale": function(val) {
      this.locale = val === "enUS" ? enUS : zhCN;
      moment.locale(val === "enUS" ? "en" : "zh-cn");
    },
    "$route.path": function() {
      if (localStorage.getItem("token")) {
        if (!!this.timInter) {
          return;
        } else {
          this.logTimeOut();
        }
      }
    }
  },
  created() {
    if (!!this.timInter) {
      window.clearInterval(this.timInter);
    }
  },
  methods: {
    logTimeOut() {
      var that = this;
      //设置超时退出
      var lastTime = new Date().getTime();
      var currentTime = new Date().getTime();

      document.addEventListener(
        "mouseover",
        function() {
          lastTime = new Date().getTime(); //更新操作时间
        },
        false
      );
      function testTime() {
        currentTime = new Date().getTime(); //更新当前时间
        if (
          currentTime - lastTime > that.timeOut &&
          that.$route.path != "/user/login"
        ) {
          if (!!that.timInter) {
            window.clearInterval(that.timInter);
          }
          //判断是否超时
          that.$nextTick(() => {
            that.$store.dispatch("login/logout");
          });
        }
      }
      /* 定时器，间隔60秒检测是否长时间未操作页面 */
      that.timInter = window.setInterval(testTime, 60000);
    }
  }
};
</script>

<style lang="less">
#app {
  height: 100%;
}
</style>
