import axios from "axios";
import { GET_FACILITIES } from "./actions.type";
import { SET_FACILITIES } from "./mutations.type";
import { baseUrl } from "../../config/config";
import { withErrorHandling } from "./utils";

const state = {
  facilities: []
};

const getters = {
  facilities(state) {
    return state.facilities == null ? [] : state.facilities.slice();
  }
};

const actions = {
  [GET_FACILITIES](context) {
    if (context.rootState.cities.userCities.length === 0) {
      return context
        .dispatch(
          "cities/getUserCities",
          {},
          {
            root: true
          }
        )
        .then(res => {
          return withErrorHandling(
            context,
            axios({
              url: baseUrl + "facility/queryFacilitySelectByIds",
              method: "GET",
              params: {
                cityIds: context.rootState.cities.userCities
                  .map(city => city.id)
                  .join(",")
              }
            }),
            res => context.commit(SET_FACILITIES, res.data.rows)
          );
        });
    }

    if (state.facilities.length) {
      return Promise.resolve();
    }

    return withErrorHandling(
      context,
      axios({
        url: baseUrl + "facility/queryFacilitySelectByIds",
        method: "GET",
        params: {
          cityIds: context.rootState.cities.userCities
            .map(city => city.id)
            .join(",")
        }
      }),
      res => context.commit(SET_FACILITIES, res.data.rows)
    );
  }
};

const mutations = {
  [SET_FACILITIES](state, facilities) {
    state.facilities = facilities;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
