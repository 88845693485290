import axios from "axios";
import router from "../router/router";
import { baseUrl } from "../config/config";

window.axios = axios;

axios.defaults.baseURL = baseUrl;
axios.interceptors.request.use(config => {
  //console.log("sending " + config.method + " request with url: " + config.url);
  // sets token and other relavant headers
  // based on which service this request will be sent to
  // contidion "!config.url.startsWith("http")" is added here because some request set path only and 
  // rely on baseURL to add the protocal and hostname part of the url
  // all endpoints should be moved to endpoints.js file in the future and provided as a complete form.
  let isRequestingFms = config.url.includes("/fms_css/") || !config.url.startsWith("http");

  if (isRequestingFms) {
    let accessToken = localStorage.getItem("token");
    config.headers["x-requested-with"] = JSON.stringify({
      accessKey: accessToken,
      cid: sessionStorage.getItem("cid") || "0",
      mid: sessionStorage.getItem("mid") || "0"
    });
  }
  else {
    // Requesting Ottergate
    let accessToken = localStorage.getItem("cssToken");
    config.headers["Authorization"] = 'Bearer ' + accessToken;
  }
  
  return config;
});

function request(options) {
  return axios(options)
    .then(res => {
      if (res.returnCode === 301) {
        localStorage.removeItem("token");
        localStorage.removeItem("cssToken");
        router.replace({
          path: "/user/login",
          query: {
            redirect: window.location.href
          }
        });
      }
      return res;
    })
    .catch(error => {
      return Promise.reject(error);
    });
}
export default request;
