import { ADD_TOAST_ACTION } from "./actions.type";

export function addSuccessToast(context, message) {
  context.commit(
    "global/" + ADD_TOAST_ACTION,
    {
      level: "info",
      message: message
    },
    {
      root: true
    }
  );
}

export function addFailureToast(context, message) {
  context.commit(
    "global/" + ADD_TOAST_ACTION,
    {
      level: "error",
      message: message
    },
    {
      root: true
    }
  );
}

export function withErrorHandling(context, promise, onSuccessFn) {
  return promise
    .then(res => {
      if (res.data.success) {
        onSuccessFn(res);
      } else {
        addFailureToast(context, `${res.data.returnMsg}`);
      }
      return res;
    })
    .catch(e => {
      addFailureToast(context, `${e}`);
    });
}
