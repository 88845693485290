import jwt_decode from "jwt-decode";

export function getAuthenticatedUserId() {
  const decodedToken = jwt_decode(localStorage.getItem("token"));
  if (!decodedToken.sub) {
    return;
  }
  const parsedUser = JSON.parse(decodedToken.sub);
  return parsedUser && parsedUser.sub;
}
