import router from "../../router/router";
import { parse } from "query-string";
import request from "@/utils/request";
import { baseUrl } from "../../config/config";

const state = {
  status: undefined
};

const actions = {
    async smsCodeLogin({ commit }, payload) {
    const response = (
      await request({
        url: baseUrl + "oauth/code",
        method: "POST",
        data: {
          ...payload,
          "clientType": "WEB",
        }
      })
    ).data;
    commit("changeLoginStatus", response);
    // Login successfully
    if (response.returnCode === "200") {
      localStorage.setItem("token", response.obj.token || "");
      localStorage.setItem("name", response.obj.name || "");
      localStorage.setItem("tokenDate", Date.now());
      localStorage.setItem("cssToken", response.obj.cssToken || "");
      sessionStorage.setItem("accessKey", response.obj.token || "");
      const urlParams = new URL(window.location.href);
      let redirect = parse(location.search).redirect;
      if (redirect) {
        const redirectUrlParams = new URL(redirect);
        if (redirectUrlParams.origin === urlParams.origin) {
          redirect = redirect.substr(urlParams.origin.length);
          if (redirect.match(/^\/.*#/)) {
            redirect = redirect.substr(redirect.indexOf("#") + 1);
          }
        } else {
          redirect = null;
        }
      }
      await router.replace({ path: redirect || "/" });
    }
    return response;
  },
  async login({ commit }, payload) {
    const response = (
      await request({
        url: baseUrl + "oauth/login",
        method: "POST",
        data: payload
      })
    ).data;
    commit("changeLoginStatus", response);
    // Login successfully
    if (response.returnCode === "200") {
      localStorage.setItem("token", response.obj.token || "");
      localStorage.setItem("name", response.obj.name || "");
      localStorage.setItem("tokenDate", Date.now());
      localStorage.setItem("cssToken", response.obj.cssToken || "");
      sessionStorage.setItem("accessKey", response.obj.token || "");
      const urlParams = new URL(window.location.href);
      let redirect = parse(location.search).redirect;
      if (redirect) {
        const redirectUrlParams = new URL(redirect);
        if (redirectUrlParams.origin === urlParams.origin) {
          redirect = redirect.substr(urlParams.origin.length);
          if (redirect.match(/^\/.*#/)) {
            redirect = redirect.substr(redirect.indexOf("#") + 1);
          }
        } else {
          redirect = null;
        }
      }
      await router.replace({ path: redirect || "/" });
    }
    return response;
  },
  async logout({ commit }) {
    commit("changeLoginStatus", {
      status: false
    });
    localStorage.removeItem("token");
    localStorage.removeItem("cssToken");
    // call go to hard reset app state
    await router.push({
      path: "/user/login"
    });
  }
};

const mutations = {
  changeLoginStatus(state, payload) {
    state.status = payload.status;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
