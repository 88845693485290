import { requirePermission } from "../utils/auth";
import { FacilityActions } from "../utils/actions";
import stalls from "../assets/stalls.png";

const KITCHEN_ROUTES = {
  path: "/stalls",
  name: "stalls",
  component: { render: h => h("router-view") },
  redirect: "/stalls/stallsList",
  meta: {
    title: "档口管理",
    icon: stalls,
    authorizationConfig: requirePermission(FacilityActions.KITCHEN_READ)
  },
  children: [
    {
      path: "/stalls/stallsList",
      name: "stallsList",
      component: () => import("@/views/stalls/stallsList"),
      meta: {
        title: "档口列表",
        keepAlive: true,
        showInMenu: true,
        authorizationConfig: requirePermission(FacilityActions.KITCHEN_READ)
      }
    },
    {
      path: "/stalls/stallsList/stallsListEdit",
      name: "stallsListEdit",
      component: () => import("@/views/stalls/stallsListDetail/stallsListEdit"),
      meta: {
        title: "编辑档口",
        authorizationConfig: requirePermission(FacilityActions.KITCHEN_EDIT)
      }
    },
    {
      path: "/stalls/stallsList/stallsListDetail",
      name: "stallsListDetail",
      component: () =>
        import("@/views/stalls/stallsListDetail/stallsListDetail"),
      meta: {
        title: "档口详情",
        authorizationConfig: requirePermission(FacilityActions.KITCHEN_READ)
      }
    }
  ]
};

export default KITCHEN_ROUTES;
