import Vue from "vue";
import Vuex from "vuex";
import global from "./modules/global";
import login from "./modules/login";
import roles from "./modules/roles";
import cities from "./modules/cities";
import users from "./modules/users";
import facilities from "./modules/facilities";
import stores from "./modules/stores";
import bills from "./modules/bills";
import deposits from "./modules/deposits";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    login,
    global,
    roles,
    cities,
    users,
    facilities,
    stores,
    bills,
    deposits,
  }
});
