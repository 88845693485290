import { requirePermission } from "../utils/auth";
import { FacilityActions } from "../utils/actions";
import store from "../assets/store.png";

const FACILITY_ROUTES = {
  path: "/store",
  name: "store",
  component: { render: h => h("router-view") },
  redirect: "/store/storeList",
  meta: {
    title: "门店管理",
    icon: store,
    authorizationConfig: requirePermission(FacilityActions.FACILITY_READ),
    showInMenu: true
  },
  children: [
    {
      path: "/store/storeList",
      name: "storeList",
      component: () => import("@/views/store/storeList"),
      meta: {
        title: "门店列表",
        keepAlive: true,
        showInMenu: true,
        authorizationConfig: requirePermission(FacilityActions.FACILITY_READ)
      }
    },
    {
      path: "/store/storeList/storeListDetail",
      name: "storeListDetail",
      component: () => import("@/views/store/storeDetail/storeListDetailNew"),
      meta: {
        title: "门店详情",
        authorizationConfig: requirePermission(FacilityActions.FACILITY_READ)
      }
    },
    {
      path: "/store/storeList/storeListEdit",
      name: "storeListEdit",
      component: () => import("@/views/store/storeDetail/storeDetailEditNew"),
      meta: {
        title: "编辑门店",
        authorizationConfig: requirePermission(FacilityActions.FACILITY_EDIT)
      }
    },
    {
      path: "/store/storeList/storeListNew",
      name: "storeListNew",
      component: () => import("@/views/store/storeDetail/storeListNew"),
      meta: {
        title: "新建门店",
        authorizationConfig: requirePermission(FacilityActions.FACILITY_CREATE)
      }
    },
    {
      path: "/store/repairOrderList",
      name: "repairOrderList",
      component: () => import("@/views/store/repairOrderList"),
      meta: {
        title: "维修工单",
        showInMenu: true,
        keepAlive: true,
        authorizationConfig: requirePermission(FacilityActions.READ_REPAIR)
      }
    },
    {
      path: "/store/repairOrderList/repairOrderDetail",
      name: "repairOrderDetail",
      component: () => import("@/views/store/repairOrderDetail"),
      meta: {
        title: "维修工单详情",
        authorizationConfig: requirePermission(FacilityActions.READ_REPAIR)
      }
    },
    {
      path: "/store/terminateLeaseList",
      name: "terminateLeaseList",
      component: () => import("@/views/store/terminateLeaseList"),
      meta: {
        title: "退租记录",
        keepAlive: true,
        showInMenu: true,
        authorizationConfig: requirePermission(
          FacilityActions.LEASE_TERMINATION_READ
        )
      }
    },
    {
      path: "/store/terminateLeaseList/terminateLeaseDetail",
      name: "terminateLeaseDetail",
      component: () => import("@/views/store/terminateLeaseDetail"),
      meta: {
        title: "退租详情",
        authorizationConfig: requirePermission(
          FacilityActions.LEASE_TERMINATION_READ
        )
      }
    },
/*    {
      path: "/store/approvalList",
      name: "approvalList",
      component: () => import("@/views/store/approvalList"),
      meta: {
        title: "开业审批",
        showInMenu: true,
        keepAlive: true,
        authorizationConfig: requirePermission(FacilityActions.READ_ONBOARDING)
      }
    },
    {
      path: "/store/approvalList/approvalDetail",
      name: "approvalDetail",
      component: () => import("@/views/store/approvalDetail"),
      meta: {
        title: "开业审批详情",
        authorizationConfig: requirePermission(FacilityActions.READ_ONBOARDING)
      }
    },*/
    {
      path: "/store/tenantEnterList",
      name: "tenantEnteringList",
      component: () => import("@/views/store/tenantEnteringList"),
      meta: {
        title: "商户入驻",
        showInMenu: true,
        keepAlive: true,
        authorizationConfig: requirePermission(FacilityActions.TENANT_ENTERING_READ)
      }
    },
    {
      path: "/store/tenantEnterList/tenantEnterDetail",
      name: "tenantEnteringDetail",
      component: () => import("@/views/store/tenantEnteringDetail"),
      meta: {
        title: "商户入驻详情",
        authorizationConfig: requirePermission(FacilityActions.TENANT_ENTERING_READ)
      }
    },
  ]
};

export default FACILITY_ROUTES;
