/*
  在Vue组件中可以使用 this.$baseUrl 代替 baseUrl，无需引用此文件。
  在axios请求中可以直接输入baseUrl后面的部分，因为已经在request.js中做了配置。
*/

let baseUrl;
let ogBaseUrl;

if (process.env.VUE_APP_API_URL) {
  // Set in the build scripts in package.json. `vue-cli-service build` produces a production
  // build but deploys to different domains. In order to route to the correct domain, we use
  // an env variable
  baseUrl = process.env.VUE_APP_API_URL;
} else {
  baseUrl = "http://" + window.location.host + "/fms_css/";
}

if (process.env.VUE_APP_OG_API_URL) {
  ogBaseUrl = process.env.VUE_APP_OG_API_URL
} else {
  ogBaseUrl = "http://" + window.location.host + "/og_css/";
}

let fmsBaseUrl = baseUrl;

export { baseUrl, fmsBaseUrl, ogBaseUrl };
