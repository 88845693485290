import { requirePermission } from "../utils/auth";
import { GroupActions, UserActions } from "../utils/actions";
import autho from "../assets/autho.png";

const AUTHORIZATION_ROUTES = {
  path: "/authorization",
  name: "authorization",
  component: { render: h => h("router-view") },
  redirect: "/authorization/organizeManage",
  meta: { title: "权限管理", icon: autho, showInMenu: true },
  children: [
    {
      path: "/authorization/roles",
      name: "roles",
      component: () => import("@/views/authorization/roles"),
      meta: {
        title: "FMS权限配置",
        showInMenu: true,
        // All users can read groups. Only those that can edit need to see this page
        authorizationConfig: requirePermission(GroupActions.GROUP_UPDATE)
      }
    },
    {
      path: "/authorization/roles/:roleId",
      name: "rolesEdit",
      component: () => import("@/views/authorization/editRole"),
      meta: {
        title: "FMS权限分配",
        // All users can read groups. Only those that can edit need to see this page
        authorizationConfig: requirePermission(GroupActions.GROUP_UPDATE)
      }
    },
    {
      path: "/authorization/actions",
      name: "actions",
      component: () => import("@/views/authorization/actions"),
      meta: {
        title: "FMS角色行动",
        showInMenu: true,
        authorizationConfig: requirePermission(
          GroupActions.RESOURCE_NAMESPACE_READ
        )
      }
    },
    {
      path: "/authorization/actions/:actionId",
      name: "actionEdit",
      component: () => import("@/views/authorization/editAction"),
      meta: {
        title: "FMS角色行动",
        authorizationConfig: requirePermission(
          GroupActions.RESOURCE_NAMESPACE_EDIT
        )
      }
    },
    {
      path: "/authorization/users",
      name: "users",
      component: () => import("@/views/authorization/users"),
      meta: {
        title: "用户管理",
        showInMenu: true,
        authorizationConfig: requirePermission(UserActions.USER_READ)
      }
    },
    {
      path: "/authorization/users/:userId",
      name: "editUser",
      component: () => import("@/views/authorization/editUser"),
      meta: {
        title: "编辑用户",
        authorizationConfig: requirePermission(UserActions.USER_UPDATE)
      }
    }
  ]
};

export default AUTHORIZATION_ROUTES;
