import axios from "axios";
import { GET_STORES, RESET_STORES } from "./actions.type";
import { SET_STORES, CLEAR_STORES } from "./mutations.type";
import { baseUrl } from "../../config/config";
import { withErrorHandling } from "./utils";

const state = {
  pagedStores: new Map(),
  tokens: new Map(),
  total: 0
};

const getters = {
  stores(state) {
    return pageNum => {
      return state.pagedStores.get(pageNum);
    };
  },
  total(state) {
    return state.total;
  }
};

const actions = {
  [GET_STORES](context, params) {
    if (state.pagedStores.has(params.page)) {
      return Promise.resolve();
    }

    return withErrorHandling(
      context,
      axios({
        url: baseUrl + "tenantStore/list",
        method: "GET",
        params: {
          ...params,
          nextToken: state.tokens.get(params.page)
        }
      }),
      res =>
        context.commit(SET_STORES, {
          data: res.data,
          page: params.page
        })
    );
  },
  [RESET_STORES](context) {
    context.commit(CLEAR_STORES);
  }
};

const mutations = {
  [SET_STORES](state, params) {
    const newPagedStores = new Map(state.pagedStores);
    newPagedStores.set(params.page, params.data.rows);
    state.pagedStores = newPagedStores;

    const newTokens = new Map(state.tokens);
    newTokens.set(params.page + 1, params.data.nextToken);
    state.tokens = newTokens;

    state.total = params.data.count;
  },
  [CLEAR_STORES](state) {
    state.pagedStores = new Map();
    state.tokens = new Map();
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
