<template>
  <div></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { REMOVE_TOAST_ACTION } from "../../store/modules/actions.type";

export default {
  name: "ToastHandler",
  computed: {
    ...mapGetters("global", ["toasts"])
  },

  methods: {
    ...mapActions("global", [REMOVE_TOAST_ACTION])
  },

  watch: {
    toasts: function(newVal) {
      if (newVal.length === 0) {
        return;
      }
      const toast = newVal[0];

      if (toast.level === "error") {
        this.$message.error(toast.message);
      } else {
        this.$message.info(toast.message);
      }

      this.removeToast(toast);
    }
  }
};
</script>

<style scoped></style>
