import { requirePermission } from "../utils/auth";
import { OrganizationActions } from "../utils/actions";
import role from "../assets/role.png";

const ORGANIZATION_ROUTES = {
  path: "/role",
  name: "role",
  component: { render: h => h("router-view") },
  redirect: "/role/RoleList",
  meta: {
    title: "客户管理",
    icon: role,
    showInMenu: true
  },
  children: [
    {
      path: "/role/shopList",
      name: "shopList",
      component: () => import("@/views/role/shopList"),
      meta: {
        title: "店铺列表",
        keepAlive: true,
        showInMenu: true,
        authorizationConfig: requirePermission(OrganizationActions.STORE_READ)
      }
    },
    {
      path: "/role/shopList/shopNew",
      name: "shopNew",
      component: () => import("@/views/role/shopDetail/shopListNew"),
      meta: {
        title: "店铺新建",
        authorizationConfig: requirePermission(OrganizationActions.STORE_CREATE)
      }
    },
    {
      path: "/role/shopList/shopEdit",
      name: "shopEdit",
      component: () => import("@/views/role/shopDetail/shopListEdit"),
      meta: {
        title: "店铺编辑",
        authorizationConfig: requirePermission(OrganizationActions.STORE_EDIT)
      }
    },
    {
      path: "/role/shopList/shopDetail",
      name: "shopDetail",
      component: () => import("@/views/role/shopDetail/shopListDetail"),
      meta: {
        title: "店铺详情",
        authorizationConfig: requirePermission(OrganizationActions.STORE_READ)
      }
    },
    {
      path: "/role/contractList",
      name: "contractList",
      component: () => import("@/views/role/contract/contractList"),
      meta: {
        title: "合同列表",
        keepAlive: true,
        showInMenu: true,
        authorizationConfig: requirePermission(
          OrganizationActions.CONTRACT_READ
        )
      }
    },
    {
      path: "/role/contractList/contractDetail",
      name: "contractListDetail",
      component: () => import("@/views/role/contract/contractDetail"),
      meta: {
        title: "合同详情",
        authorizationConfig: requirePermission(
          OrganizationActions.CONTRACT_READ
        )
      }
    },
//    {
//      path: "/role/brandList",
//      name: "brandList",
//      component: () => import("@/views/role/brandList"),
//      meta: {
//        title: "品牌列表",
//        keepAlive: true,
//        showInMenu: true,
//        authorizationConfig: requirePermission(OrganizationActions.BRAND_READ)
//      }
//    },
    {
      path: "/role/brandList/brandListEdit",
      name: "brandListEdit",
      component: () => import("@/views/role/brandList/brandListEdit"),
      meta: {
        title: "编辑品牌",
        authorizationConfig: requirePermission(OrganizationActions.BRAND_EDIT)
      }
    },
    {
      path: "/role/brandList/brandListDetail",
      name: "brandListDetail",
      component: () => import("@/views/role/brandList/brandListDetail"),
      meta: {
        title: "品牌详情",
        authorizationConfig: requirePermission(OrganizationActions.BRAND_READ)
      }
    },
    {
      path: "/role/RoleList",
      name: "RoleList",
      component: () => import("@/views/role/RoleList"),
      meta: {
        title: "客户列表",
        keepAlive: true,
        showInMenu: true,
        authorizationConfig: requirePermission(OrganizationActions.CLIENT_READ)
      }
    },
    {
      path: "/role/RoleList/roleListDetail",
      name: "roleListDetail",
      component: () => import("@/views/role/roleListDetail/roleListDetail"),
      meta: {
        title: "客户详情",
        authorizationConfig: requirePermission(OrganizationActions.CLIENT_READ)
      }
    },
    {
      path: "/role/RoleList/roleListEdit",
      name: "roleListEdit",
      component: () => import("@/views/role/roleListDetail/roleListEdit"),
      meta: {
        title: "编辑客户",
        authorizationConfig: requirePermission(OrganizationActions.CLIENT_READ)
      }
    },
    {
      path: "/role/contractTemplates",
      name: "contractTemplates",
      component: () => import("@/views/role/templates/contractTemplates"),
      meta: {
        title: "合同模版管理",
        keepAlive: true,
        showInMenu: true,
        authorizationConfig: requirePermission(
          OrganizationActions.CONTRACT_EDIT
        )
      }
    }
  ]
};

export default ORGANIZATION_ROUTES;
