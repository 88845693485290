const PERMIT_ALL = [];

export function permitAll() {
  return {
    permissions: PERMIT_ALL,
    unauthenticated: false
  };
}

export function unauthenticated() {
  return {
    permissions: [],
    unauthenticated: true
  };
}

// Sets a permission that the user must have to be able to do this action (view a page, button, etc)
export function requirePermission(action) {
  if(!action) {
    console.log(`ERROR: Router is requiring a action which is not exist!`);
  }

  return {
    permissions: [action],
    unauthenticated: false
  };
}

export function check(route, userActions) {
  // If no permissions were set, mark it as unauthorized
  if (!route || !route.meta || !route.meta.authorizationConfig) {
    return false;
  }
  const authorizationConfig = route.meta.authorizationConfig;
  // Allow unauthenticated requests
  if (authorizationConfig.unauthenticated) {
    return true;
  }

  // Make sure the permissions are well-formed. If authorize is undefined, fail the check.
  if (!authorizationConfig.permissions) {
    return false;
  }

  // Check our permit all marker (empty list)
  if (authorizationConfig.permissions.length === 0 && isAuthenticated()) {
    return true;
  }

  return hasPermissions(authorizationConfig.permissions, userActions);
}

export function hasPermissions(requiredActions, userActions) {
  for (const requiredAction of requiredActions) {
    const requiredActionPermissions = new Set();

    let accumulated = "";
    requiredAction.split(":").forEach(part => {
      requiredActionPermissions.add(accumulated + "*");
      accumulated = `${accumulated}${part}:`;
    });
    requiredActionPermissions.add(accumulated.slice(0, -1));

    if (!hasIntersection(requiredActionPermissions, userActions)) {
      return false;
    }
  }
  return true;
}

function hasIntersection(a, b) {
  return [...a].filter(i => b.has(i)).length > 0;
}

export function isAuthenticated() {
  return !!localStorage.getItem("token");
}

export function hasPermissionsFun(requiredActions, userActions) {
  const length = [...requiredActions].filter(i => userActions.has(i)).length;
  return length > 0;
}